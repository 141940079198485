import * as React from "react"
import { IOptionalElementProps, OptionalElement } from "./optional-element"

interface IWebsiteButton extends IOptionalElementProps {
	content: string
	contentUpdate(content: string): void
	isEnglish: boolean
}
export default class WebsiteButton extends OptionalElement<IWebsiteButton> {
	protected renderSpecificContent(): JSX.Element {
		const { isEnglish } = this.props
		return (
			<div className="col-12">
				<a
					href="https://www.flughafen-stuttgart.de/"
					className="btn btn-dark website__btn"
				>
					{isEnglish === true ? "Website" : "Zur Hauptseite"}
				</a>
			</div>
		)
	}
}
