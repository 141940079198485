/* eslint no-param-reassign: ["error", { "props": false }] */
// I changed a linter rule here to set the effect of the drag event
import * as React from 'react';

interface IContentElement {
	type: string,
	title: string,
	content: string,
	uuid: string
};

interface IDropTargetProps {
	moveElementHere(uuid: string): void,
	createElementHere(newElement: IContentElement): void,
	setDragging(dragging: boolean): void,
}

function dragEnter(ev: React.DragEvent): void {
	ev.dataTransfer.dropEffect = 'move';
}

function dragOver(ev: React.DragEvent): void {
	ev.preventDefault();
	ev.dataTransfer.dropEffect = 'move';
}

export class DropTarget extends React.Component<IDropTargetProps> {

	constructor(props: IDropTargetProps) {
		super(props);

		this.drop = this.drop.bind(this);
	}

	private drop(ev: React.DragEvent): void {
		const {
			moveElementHere,
			createElementHere,
			setDragging,
		} = this.props;

		const droppedItem = ev.dataTransfer.getData('element_id');
		if (droppedItem) {
			moveElementHere(droppedItem);
		} else if (ev.dataTransfer.getData('type')) {
			const newContent = {
				type: ev.dataTransfer.getData('type'),
				title: ev.dataTransfer.getData('title'),
				content: ev.dataTransfer.getData('content'),
				uuid: ev.dataTransfer.getData('uuid'),
			};
			createElementHere(newContent);
		}
		setDragging(false);
	}

	render(): JSX.Element {
		return (
			<div
				className="drop-target"
				onDragOver={dragOver}
				onDrop={this.drop}
				onDragEnter={dragEnter}
			>
				<div className="text-center">
					Hier einfügen
				</div>
			</div>
		)
	}
}
