import * as React from "react"
import { useEffect, useRef } from "react"
import * as Loadable from "react-loadable"

import AddContent from "./add-content"
import ContentHeader from "./content-header"
import { DropTarget } from "./drop-target"
import Footer from "./footer"
import Head from "./head"
import InformationBoxes from "./information-boxes"
import PressContact from "./press-contact"
import InteractiveModule from "./search-bar"
import SearchBar from "./search-bar"
import TextBlock from "./text-block"
import WebsiteButton from "./website-button"

type ContentEntry = {
	type: string
	title: string
	content: string
	uuid: string
	hide: boolean
}

type LoaderProps = Loadable.LoadingComponentProps

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
	return <div>Loading...</div>
}

const ImportantInfo = Loadable({
	loader: () => import("./important-info"),
	loading: Loader,
})

function makeDropSectionsVisible(visible: boolean): void {
	document.querySelectorAll(".drop-section").forEach((dropSection) => {
		if (visible) {
			dropSection.classList.remove("section--hidden")
		} else if (!dropSection.classList.contains("section--hidden")) {
			dropSection.classList.add("section--hidden")
		}
	})
}

interface IContentProperties {
	isAdmin?: boolean
	isEnglish?: boolean
	data: Array<ContentEntry>
}

const activate = (): void => {
	if (prompt("DARKSITE AKTIVIEREN") === 'fsg!1924!str') {
		fetch(`${process.env.BASEPATH ?? ""}/api/enabledarksite`)
			.then((nonJsonContent) => alert("DARKSITE AKTIVIERT"))
			.catch(() => alert("DARKSITE NICHT AKTIVIERT"))
	} else {
		alert("Password falsch! Darksite NICHT aktiviert")
	}
}

const deactivate = (): void => {
	fetch(`${process.env.BASEPATH ?? ""}/api/disabledarksite`)
		.then((nonJsonContent) => alert("DARKSITE DEAKTIVIERT"))
		.catch(() => alert("DARKSITE NICHT DEAKTIVIERT"))
}

export default (props: IContentProperties): JSX.Element => {
	const isAdmin = props.isAdmin ?? false
	const { isEnglish } = props
	const { data } = props
	const [content, setContent] = React.useState<Array<ContentEntry>>(data)

	const send = (): void => {
		const request = new XMLHttpRequest()
		if (isEnglish) {
			request.open("POST", `${process.env.BASEPATH ?? ""}/api/content_en`)
		} else {
			request.open("POST", `${process.env.BASEPATH ?? ""}/api/content`)
		}
		request.setRequestHeader("Content-Type", "application/json")
		request.addEventListener("load", (): void => {
			if (request.status === 200) {
				alert("Success")
			} else if (request.status !== 200) {
				alert(`Request failed.  Returned status of ${request.status}`)
			}
		})
		request.send(JSON.stringify({ content }))
	}

	const addElementToContent = (element: ContentEntry, index: number): void => {
		const contentCopy = [...content]
		contentCopy.splice(index, 0, element)
		setContent(contentCopy)
	}

	const [isClient, setClient] = React.useState(false)
	const key = isClient ? "client" : "server"
	useEffect(() => {
		setClient(true)
	}, [])

	const AddContentHere = (props: { index: number }): JSX.Element => {
		const { index } = props

		return (
			<>
				<section className="drop-section section__content section--hidden">
					<div className="container">
						<DropTarget
							moveElementHere={(uuid: string): void => {
								const indexToMove = content.findIndex(
									(contentEntry: ContentEntry): boolean => contentEntry.uuid === uuid
								)
								if (indexToMove === -1) {
									console.error(`Element with uuid ${uuid} not found`)
									return
								}

								const toMove = content[indexToMove]
								const contentCopy = [...content]

								if (index > indexToMove) {
									contentCopy.splice(index, 0, toMove)
									contentCopy.splice(indexToMove, 1)
								} else {
									contentCopy.splice(indexToMove, 1)
									contentCopy.splice(index, 0, toMove)
								}

								setContent(contentCopy)
							}}
							createElementHere={(newElement: ContentEntry): void => {
								addElementToContent(newElement, index)
							}}
							setDragging={makeDropSectionsVisible}
						/>
					</div>
				</section>
			</>
		)
	}

	return (
		<div key={key} className={isAdmin ? "isAdmin" : "isClient"}>
			<Head />
			{isAdmin && <AddContent setDragging={makeDropSectionsVisible} />}
			{isAdmin && <ContentHeader isAdmin />}
			{!isAdmin && <ContentHeader />}
			{isAdmin && <AddContentHere index={0} />}
			{content.map(
				(contentEntry: ContentEntry, index: number): JSX.Element => {
					switch (contentEntry.type) {
						case "ImportantInfo": {
							return (
								<div key={contentEntry.uuid} className="py-4">
									<ImportantInfo
										title={contentEntry.title}
										content={contentEntry.content}
										uuid={contentEntry.uuid}
										isAdmin={isAdmin}
										key={contentEntry.uuid}
										titleUpdate={(update): void => {
											content[index].title = update
											const tmpContent = content.slice()
											setContent(tmpContent)
										}}
										contentUpdate={(update): void => {
											content[index].content = update
											setContent(content)
										}}
										hide={contentEntry.hide}
										hideFunction={(): void => {
											content[index].hide = content[index].hide !== true
											const tmpContent = content.slice()
											setContent(tmpContent)
										}}
										remove={(): void => {
											const tmpCopy = [...content]
											tmpCopy.splice(index, 1)
											setContent(tmpCopy)
										}}
										setDragging={makeDropSectionsVisible}
									/>
									{isAdmin && <AddContentHere index={index + 1} />}
								</div>
							)
						}
						case "WebsiteButton": {
							return (
								<>
									<WebsiteButton
										title={contentEntry.title}
										content={contentEntry.content}
										uuid={contentEntry.uuid}
										key={contentEntry.uuid}
										hide={contentEntry.hide}
										isEnglish={isEnglish}
										hideFunction={(): void => {
											content[index].hide = content[index].hide !== true
											const tmpContent = content.slice()
											setContent(tmpContent)
										}}
										isAdmin={isAdmin}
										titleUpdate={(update): void => {
											content[index].title = update
											setContent(content)
										}}
										contentUpdate={(update): void => {
											content[index].content = update
											setContent(content)
										}}
										remove={(): void => {
											const tmpCopy = [...content]
											tmpCopy.splice(index, 1)
											setContent(tmpCopy)
										}}
										setDragging={makeDropSectionsVisible}
									/>
									{isAdmin && <AddContentHere index={index + 1} />}
								</>
							)
						}
						case "TextBlock": {
							return (
								<div key={contentEntry.uuid}>
									<TextBlock
										title={contentEntry.title}
										content={contentEntry.content}
										uuid={contentEntry.uuid}
										key={contentEntry.uuid}
										hide={contentEntry.hide}
										hideFunction={(): void => {
											content[index].hide = content[index].hide !== true
											const tmpContent = content.slice()
											setContent(tmpContent)
										}}
										isAdmin={isAdmin}
										titleUpdate={(update): void => {
											content[index].title = update
											setContent(content)
										}}
										contentUpdate={(update): void => {
											content[index].content = update
											setContent(content)
										}}
										remove={(): void => {
											const tmpCopy = [...content]
											tmpCopy.splice(index, 1)
											setContent(tmpCopy)
										}}
										setDragging={makeDropSectionsVisible}
									/>
									{isAdmin && <AddContentHere index={index + 1} />}
								</div>
							)
						}
						case "PressContactInformation":
							return (
								<div key={contentEntry.uuid}>
									<PressContact
										content={contentEntry.content}
										uuid={contentEntry.uuid}
										title={contentEntry.title}
										isAdmin={isAdmin}
										isEnglish={isEnglish}
										key={contentEntry.uuid}
										hide={contentEntry.hide}
										hideFunction={(): void => {
											content[index].hide = content[index].hide !== true
											const tmpContent = content.slice()
											setContent(tmpContent)
										}}
										titleUpdate={(update): void => {
											content[index].title = update
											setContent(content)
										}}
										remove={(): void => {
											const tmpCopy = [...content]
											tmpCopy.splice(index, 1)
											setContent(tmpCopy)
										}}
										contentUpdate={(update): void => {
											content[index].content = update
											setContent(content)
										}}
										setDragging={makeDropSectionsVisible}
									/>
									{isAdmin && <AddContentHere index={index + 1} />}
								</div>
							)
						case "SearchBar":
							return (
								<div key={contentEntry.uuid}>
									<InteractiveModule
										language={isEnglish ? "en" : "de"}
										key={contentEntry.uuid}
										content={contentEntry.content}
										uuid={contentEntry.uuid}
										isAdmin={isAdmin}
										hide={contentEntry.hide}
										hideFunction={(): void => {
											content[index].hide = content[index].hide !== true
											const tmpContent = content.slice()
											setContent(tmpContent)
										}}
										contentUpdate={(update): void => {
											content[index].content = update
											setContent(content)
										}}
										setDragging={makeDropSectionsVisible}
									/>
									{isAdmin && <AddContentHere index={index + 1} />}
								</div>
							)
						case "InformationBoxes":
							return (
								<div key={contentEntry.uuid}>
									<InformationBoxes
										title={contentEntry.title}
										content={contentEntry.content}
										uuid={contentEntry.uuid}
										isAdmin={isAdmin}
										key={contentEntry.uuid}
										hide={contentEntry.hide}
										hideFunction={(): void => {
											content[index].hide = content[index].hide !== true
											const tmpContent = content.slice()
											setContent(tmpContent)
										}}
										updateContent={(update): void => {
											content[index].content = update
											setContent(content)
										}}
										updateTitle={(update): void => {
											content[index].title = update
											setContent(content)
										}}
										remove={(): void => {
											const tmpCopy = [...content]
											tmpCopy.splice(index, 1)
											setContent(tmpCopy)
										}}
										setDragging={makeDropSectionsVisible}
									/>
									{isAdmin && <AddContentHere index={index + 1} />}
								</div>
							)
						default:
							return <br />
					}
				}
			)}
			{isAdmin && (
				<button
					className="button button--generic button--left-bottom-corner button--red send--button"
					onClick={send}
					type="button"
				>
					ÄNDERUNGEN SPEICHERN
				</button>
			)}
			{isAdmin && (
				<button
					className="button button--generic button--left-bottom-corner button--red activate--button"
					onClick={activate}
					type="button"
				>
					DARKSITE AKTIVIEREN
				</button>
			)}
			{isAdmin && (
				<button
					className="button button--generic button--left-bottom-corner button--red deactivate--button"
					onClick={deactivate}
					type="button"
				>
					DARKSITE DEAKTIVIEREN
				</button>
			)}
			<Footer suffix={isEnglish ? "en" : "de"} />
		</div>
	)
}
