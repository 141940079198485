import * as React from "react"
import { v4 as uuidv4 } from "uuid"
import { EditorState, ContentState } from "draft-js"
import { serializeEditorState } from "../util/editor"
import { DragCreationSource } from "./drag-creation-source"

interface IContentElement {
	type: string
	title: string
	content: string
	uuid: string
}

interface IAddContentProperties {
	setDragging(newDragging: boolean): void
}

function createSerializedContent(text: string): string {
	return serializeEditorState(
		EditorState.createWithContent(ContentState.createFromText(text))
	)
}

function addTextBlock(): IContentElement {
	return {
		type: "TextBlock",
		title: createSerializedContent("Title"),
		content: createSerializedContent("Content"),
		uuid: uuidv4(),
	}
}

function addImportantInfo(): IContentElement {
	return {
		type: "ImportantInfo",
		title: createSerializedContent("Title"),
		content: createSerializedContent("Content"),
		uuid: uuidv4(),
	}
}

function addInformationBoxes(): IContentElement {
	return {
		type: "InformationBoxes",
		title: createSerializedContent("Title"),
		content: "[]",
		uuid: uuidv4(),
	}
}

function addWebsiteButton(): IContentElement {
	return {
		type: "WebsiteButton",
		title: "",
		content: "[]",
		uuid: uuidv4(),
	}
}

function addFlightPlan(): IContentElement {
	return {
		type: "SearchBar",
		title: "",
		content: "[]",
		uuid: uuidv4(),
	}
}

function addPressContact(): IContentElement {
	return {
		type: "PressContactInformation",
		title: "",
		content: createSerializedContent("Title"),
		uuid: uuidv4(),
	}
}

export default (props: IAddContentProperties): JSX.Element => {
	const { setDragging } = props

	return (
		<>
			<div className="side-bar">
				<h3>Neue Elemente</h3>
				<DragCreationSource
					createNewContent={(): IContentElement => addTextBlock()}
					setDragging={setDragging}
				>
					Textblock
				</DragCreationSource>
				<DragCreationSource
					createNewContent={(): IContentElement => addImportantInfo()}
					setDragging={setDragging}
				>
					Wichtige Information
				</DragCreationSource>
				<DragCreationSource
					createNewContent={(): IContentElement => addFlightPlan()}
					setDragging={setDragging}
				>
					Flugplan
				</DragCreationSource>
				<DragCreationSource
					createNewContent={(): IContentElement => addInformationBoxes()}
					setDragging={setDragging}
				>
					Adressboxen
				</DragCreationSource>
				<DragCreationSource
					createNewContent={(): IContentElement => addWebsiteButton()}
					setDragging={setDragging}
				>
					WebsiteButton
				</DragCreationSource>
				<DragCreationSource
					createNewContent={(): IContentElement => addPressContact()}
					setDragging={setDragging}
				>
					Pressebereich
				</DragCreationSource>
			</div>
		</>
	)
}
