import * as React from "react"
import * as Loadable from "react-loadable"
import { EditorState, ContentState } from "draft-js"
import { deserializeEditorState, serializeEditorState } from "../util/editor"

type LoaderProps = Loadable.LoadingComponentProps

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
	return <div>Loading...</div>
}

const Byte5Editor = Loadable({
	loader: () => import("byte5-editor"),
	loading: Loader,
})

type Parameters = {
	serializedEditorState: string
	textChanged(serializedState: string): void
	hide: boolean
}

export function EditableEditor({
	serializedEditorState,
	textChanged,
}: Parameters): JSX.Element {
	const editorState = deserializeEditorState(serializedEditorState)
	return (
		<Byte5Editor
			initialContent={editorState.getCurrentContent()}
			updateContent={(newContent: ContentState): void => {
				let tempContent
				try {
					tempContent = ContentState.createFromBlockArray(
						newContent.getBlocksAsArray()
					)
					const newEditorState = EditorState.createWithContent(tempContent)
					textChanged(serializeEditorState(newEditorState))
				} catch (error) {
					tempContent = ContentState.createFromText(newContent.getPlainText())
					const newEditorState = EditorState.createWithContent(tempContent)
					textChanged(serializeEditorState(newEditorState))
				}
			}}
		/>
	)
}
