import { convertFromRaw, convertToRaw, EditorState } from "draft-js"

export function deserializeEditorState(serializedState: string): EditorState {
	return EditorState.createWithContent(convertFromRaw(JSON.parse(serializedState)))
}

export function serializeEditorState(editorState: EditorState): string {
	return JSON.stringify(convertToRaw(editorState.getCurrentContent()))
}

export function editorStateToPlainText(editorState: EditorState): string {
	return editorState.getCurrentContent().getPlainText()
}

export function serializedEditorStateToPlainText(serialized: string): string {
	return editorStateToPlainText(deserializeEditorState(serialized))
}
