import * as React from "react"
import { EditorState, ContentState } from "draft-js"
import { v4 as uuidv4 } from "uuid"
import phoneIcon from "icons/svg/006_telefon.svg"
import faxIcon from "icons/svg/008_fax.svg"
import emailIcon from "icons/svg/011_email.svg"
import webIcon from "icons/svg/002_web.svg"
import airplaneIcon from "icons/svg/022_fluggesellschaft.svg"
import addIcon from "icons/svg/plus-solid.svg"
import { ReadOnlyEditor, ReadOnlyEditorFromText } from "./readonly-editor"
import { EditableEditor } from "./editable-editor"
import {
	serializeEditorState,
	serializedEditorStateToPlainText,
} from "../util/editor"
import { IOptionalElementProps, OptionalElement } from "./optional-element"

interface ISingleBox {
	uuid: string
	title: string
	phone?: string
	fax?: string
	email?: string
	web?: string
}

interface ISingleBoxWithUpdates extends ISingleBox {
	updateBoxTitle(newBoxTitle: string): void
	updatePhone(newPhone: string): void
	updateFax(newFax: string): void
	updateEmail(newEmail: string): void
	updateWeb(newWeb: string): void
	isAdmin: boolean
}

export function BoxComponent(props: ISingleBoxWithUpdates): JSX.Element {
	const {
		title,
		phone,
		fax,
		email,
		web,
		isAdmin,
		updateBoxTitle,
		updatePhone,
		updateFax,
		updateEmail,
		updateWeb,
	} = props
	return (
		<div className="widget">
			{(title !== "" || isAdmin) && (
				<h3 className="widget__title">
					{isAdmin ? (
						<EditableEditor
							serializedEditorState={title}
							textChanged={updateBoxTitle}
						/>
					) : (
						<ReadOnlyEditor serializedEditorState={title} />
					)}
				</h3>
			)}
			<ul>
				{phone && (
					<li className="widget__entry">
						<img
							draggable={false}
							className="icon widget__icon"
							src={phoneIcon}
							alt="Phone"
						/>
						<span className="widget__label">
							{isAdmin ? (
								<EditableEditor
									serializedEditorState={phone}
									textChanged={updatePhone}
								/>
							) : (
								<ReadOnlyEditor serializedEditorState={phone} />
							)}
						</span>
					</li>
				)}
				{fax && ((!isAdmin && serializedEditorStateToPlainText(fax)) || isAdmin) && (
					<li className="widget__entry">
						<img
							draggable={false}
							className="icon widget__icon"
							src={faxIcon}
							alt="Fax"
						/>
						<span className="widget__label">
							{isAdmin ? (
								<EditableEditor
									serializedEditorState={fax}
									textChanged={updateFax}
								/>
							) : (
								<ReadOnlyEditor serializedEditorState={fax} />
							)}
						</span>
					</li>
				)}
				{email &&
					((!isAdmin && serializedEditorStateToPlainText(email)) || isAdmin) && (
						<li className="widget__entry">
							<img
								draggable={false}
								className="icon widget__icon"
								src={emailIcon}
								alt="Email"
							/>
							{isAdmin ? (
								<EditableEditor
									serializedEditorState={email}
									textChanged={updateEmail}
								/>
							) : (
								<span className="widget__label">
									<a
										href={`mailto:${serializedEditorStateToPlainText(email)}`}
										className="link"
									>
										<ReadOnlyEditor serializedEditorState={email} />
									</a>
								</span>
							)}
						</li>
					)}
				{web && ((!isAdmin && serializedEditorStateToPlainText(web)) || isAdmin) && (
					<li className="widget__entry">
						<img
							draggable={false}
							className="icon widget__icon"
							src={webIcon}
							alt="Web"
						/>
						{isAdmin ? (
							<EditableEditor serializedEditorState={web} textChanged={updateWeb} />
						) : (
							<a href={serializedEditorStateToPlainText(web)} className="link" target="_blank">
								<span className="widget__label link--imp">
									<ReadOnlyEditorFromText
										text={serializedEditorStateToPlainText(web)}
									/>
								</span>
							</a>
						)}
					</li>
				)}
			</ul>
		</div>
	)
}

function toTriplets<T>(array: Array<T>): Array<Array<T>> {
	return array.reduce((result, value, index, original): Array<Array<T>> => {
		if (index % 3 === 0) result.push(original.slice(index, index + 3))
		return result
	}, [])
}

export function emptyBox(): ISingleBox {
	return {
		title: serializeEditorState(
			EditorState.createWithContent(ContentState.createFromText("Title"))
		),
		phone: serializeEditorState(
			EditorState.createWithContent(ContentState.createFromText("Phone"))
		),
		email: serializeEditorState(
			EditorState.createWithContent(ContentState.createFromText("Email"))
		),
		fax: serializeEditorState(
			EditorState.createWithContent(ContentState.createFromText("Fax"))
		),
		web: serializeEditorState(
			EditorState.createWithContent(ContentState.createFromText("Web"))
		),
		uuid: uuidv4(),
	}
}

interface IInformationBoxProperties extends IOptionalElementProps {
	title: string
	content: string
	updateContent(newContent: string): void
	updateTitle(newTitle: string): void
}

interface IInformationBoxState {
	boxes: Array<ISingleBox>
}

export default class InformationBoxes extends OptionalElement<
	IInformationBoxProperties,
	IInformationBoxState
> {
	constructor(props: IInformationBoxProperties) {
		super(props)

		const { content } = this.props

		this.state = {
			boxes: JSON.parse(content),
		}
	}

	protected renderSpecificContent(): JSX.Element {
		const { title, isAdmin, updateContent, updateTitle } = this.props

		const { boxes } = this.state

		return (
			<div className="container">
				<div className="row">
					<div className="col-12">
						{(isAdmin || JSON.parse(title).blocks[0].text) && (
							<h2 className="headline--lg text-center">
								{isAdmin ? (
									<EditableEditor
										serializedEditorState={title}
										textChanged={updateTitle}
									/>
								) : (
									<ReadOnlyEditor serializedEditorState={title} />
								)}
							</h2>
						)}
					</div>
				</div>

				{toTriplets(boxes).map(
					(boxTriplet, indexOfTriplet): JSX.Element => {
						return (
							<div className="row">
								{boxTriplet.map(
									(box, indexInTriplet): JSX.Element => (
										<div className="col-12 col-md-4" key={box.uuid}>
											{isAdmin && (
												<button
													type="button"
													className="button button--generic"
													onClick={(): void => {
														this.setState(
															(prevState): IInformationBoxState => {
																const copyOfBoxes = [...prevState.boxes]
																copyOfBoxes.splice(
																	3 * indexOfTriplet + indexInTriplet,
																	1
																)
																return {
																	boxes: copyOfBoxes,
																}
															}
														)
													}}
												>
													X
												</button>
											)}
											<div>
												<BoxComponent
													title={box.title}
													updateBoxTitle={(newBoxTitle): void => {
														boxes[
															3 * indexOfTriplet + indexInTriplet
														].title = newBoxTitle
														this.setState({ boxes })
														updateContent(JSON.stringify(boxes))
													}}
													email={box.email}
													updateEmail={(newEmail): void => {
														boxes[
															3 * indexOfTriplet + indexInTriplet
														].email = newEmail
														this.setState({ boxes })
														updateContent(JSON.stringify(boxes))
													}}
													fax={box.fax}
													updateFax={(newFax): void => {
														boxes[3 * indexOfTriplet + indexInTriplet].fax = newFax
														this.setState({ boxes })
														updateContent(JSON.stringify(boxes))
													}}
													phone={box.phone}
													updatePhone={(newPhone): void => {
														boxes[
															3 * indexOfTriplet + indexInTriplet
														].phone = newPhone
														this.setState({ boxes })
														updateContent(JSON.stringify(boxes))
													}}
													web={box.web}
													updateWeb={(newWeb): void => {
														boxes[3 * indexOfTriplet + indexInTriplet].web = newWeb
														this.setState({ boxes })
														updateContent(JSON.stringify(boxes))
													}}
													uuid={box.uuid}
													isAdmin={isAdmin}
												/>
											</div>
										</div>
									)
								)}
							</div>
						)
					}
				)}
				{isAdmin && (
					<section className="section__content">
						<div className="container">
							<button
								type="button"
								className="button button--generic"
								onClick={(): void => {
									this.setState({ boxes: [...boxes, emptyBox()] })
									updateContent(JSON.stringify(boxes))
								}}
							>
								<img
									draggable={false}
									className="icon"
									src={addIcon}
									alt="Hinzufügen"
								/>
							</button>
						</div>
					</section>
				)}
			</div>
		)
	}
}
