/* eslint no-param-reassign: ["error", { "props": false }] */
// I changed a linter rule here to set the allowed effect of the drag event
import * as React from 'react';

interface IDragSourceProps {
	uuidToMove: string
	setDragging(dragging: boolean): void,
}

export class DragMoveSource extends React.Component<IDragSourceProps> {

	constructor(props: IDragSourceProps) {
		super(props);

		this.startDrag = this.startDrag.bind(this);
		this.stopDrag = this.stopDrag.bind(this);
	}

	private startDrag(ev: React.DragEvent<HTMLDivElement>): void {
		const {
			uuidToMove,
			setDragging,
		} = this.props;

		const elementToMove = document.querySelector(`#content${uuidToMove}`);
		const horizontalOffset = 0.5 * elementToMove.clientWidth;
		const verticalOffset = 0.5 * elementToMove.clientHeight;

		ev.stopPropagation();
		ev.dataTransfer.setData('element_id', uuidToMove);
		ev.dataTransfer.effectAllowed = 'move';
		ev.dataTransfer.setDragImage(elementToMove, horizontalOffset, verticalOffset);
		setTimeout(() => { setDragging(true); }, 10);
	}

	private stopDrag(ev: React.DragEvent<HTMLDivElement>): void {
		const {
			setDragging,
		} = this.props;
		ev.stopPropagation();
		setDragging(false);
	}

	render(): JSX.Element {
		const {
			children,
		} = this.props;
		return (
			<div draggable onDragStart={this.startDrag} onDragEnd={this.stopDrag}>
				{children}
			</div>
		)
	}
}

