import * as React from "react"
import removeIcon from "icons/svg/trash-alt-solid.svg"
import dragIcon from "icons/svg/bars-solid.svg"
import search from "icons/svg/search.svg"
import { DragMoveSource } from "./drag-move-source"

export interface IOptionalElementProps {
	isAdmin?: boolean
	uuid: string
	remove(): void
	hideFunction(): void
	hide: boolean
	setDragging(dragging: boolean): void
}

export abstract class OptionalElement<
	IPropInterface extends IOptionalElementProps,
	State = {}
> extends React.Component<IPropInterface, State> {
	protected abstract renderSpecificContent(): JSX.Element

	render(): JSX.Element {
		const { isAdmin, remove, uuid, setDragging, hideFunction, hide } = this.props
		return (
			<section className="section__content" id={uuid} key={uuid}>
				<div className="container">
					{isAdmin && (
						<div className="element-bar">
							<DragMoveSource uuidToMove={uuid} setDragging={setDragging}>
								<div className="element-bar--item">
									<img className="icon" src={dragIcon} alt="Drag" title="Element verschieben" />
								</div>
							</DragMoveSource>
							<button
								type="button"
								className="button element-bar--item"
								title="Element löschen"
								onClick={remove}
							>
								<img
									draggable={false}
									className="icon"
									src={removeIcon}
									alt="Entfernen"
								/>
							</button>
							<button
								type="button"
								className="button element-bar--item"
								title="Element ausblenden / einblenden"
								onClick={() => {
									hideFunction()
								}}
							>
								<img
									draggable={false}
									className="icon"
									src={search}
									alt="Ausblenden"
								/>
							</button>
						</div>
					)}
					<div
						className={`row ${hide ? "hide-content" : ""}`}
						id={"content".concat(uuid)}
					>
						{this.renderSpecificContent()}
					</div>
				</div>
			</section>
		)
	}
}
