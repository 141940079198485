/* eslint no-param-reassign: ["error", { "props": false }] */
// I changed a linter rule here to set the allowed effect of the drag event
import * as React from 'react';

interface IContentElement {
	type: string,
	title: string,
	content: string,
	uuid: string
};

interface IDragCreationSourceProps {
	createNewContent(): IContentElement,
	setDragging(dragging: boolean): void,
}

export class DragCreationSource extends React.Component<IDragCreationSourceProps> {

	constructor(props: IDragCreationSourceProps) {
		super(props);

		this.startDrag = this.startDrag.bind(this);
		this.stopDrag = this.stopDrag.bind(this);
	}

	private startDrag(ev: React.DragEvent<HTMLDivElement>): void {
		const {
			createNewContent,
			setDragging
		} = this.props;

		const newContent = createNewContent();

		ev.dataTransfer.setData('title', newContent.title);
		ev.dataTransfer.setData('type', newContent.type);
		ev.dataTransfer.setData('content', newContent.content);
		ev.dataTransfer.setData('uuid', newContent.uuid);
		ev.dataTransfer.effectAllowed = 'move';
		setDragging(true);
	}

	private stopDrag(): void {
		const {
			setDragging,
		} = this.props;
		setDragging(false);
	}

	render(): JSX.Element {
		const {
			children,
		} = this.props;
		return (
			<div className="drag-source" draggable onDragStart={this.startDrag} onDragEnd={this.stopDrag}>
				{children}
			</div>
		)
	}
}
