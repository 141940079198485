import * as React from 'react';
import { ReadOnlyEditor } from './readonly-editor';
import { EditableEditor } from './editable-editor';
import { IOptionalElementProps, OptionalElement } from './optional-element';

interface ITextBlockProps extends IOptionalElementProps {
	title: string,
	content: string,
	titleUpdate(title: string): void,
	contentUpdate(content: string): void,
}

export default class TextBlock extends OptionalElement<ITextBlockProps> {

	private editableContent(): JSX.Element {
		const {
			title,
			content,
			titleUpdate,
			contentUpdate,
		} = this.props;

		return (
			<>
				<h2 className="headline--lg text-center">
					<EditableEditor
						serializedEditorState={title}
						textChanged={titleUpdate}
					/>
				</h2>
				<EditableEditor
					serializedEditorState={content}
					textChanged={contentUpdate}
				/>
			</>
		);
	};

	private readOnlyContent(): JSX.Element {
		const {
			title,
			content,
		} = this.props;

		return (
			<>
				<h2 className="headline--lg text-center">
					<ReadOnlyEditor serializedEditorState={title} />
				</h2>
				<ReadOnlyEditor serializedEditorState={content.replace(/_self/g, `_blank`)} />
			</>
		);
	};


	protected renderSpecificContent(): JSX.Element {
		const {
			isAdmin,
		} = this.props;

		return (
			<div className="col-12">
				{isAdmin
					? this.editableContent()
					: this.readOnlyContent()
				}
			</div>
		);

	}
}
