import * as React from "react"
import * as Loadable from "react-loadable"
import { ContentState } from "draft-js"
import { deserializeEditorState } from "../util/editor"

type LoaderProps = Loadable.LoadingComponentProps

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
	return <div>Loading...</div>
}

const Byte5Editor = Loadable({
	loader: () => import("byte5-editor"),
	loading: Loader,
})

type Parameters = { serializedEditorState: string }

export function ReadOnlyEditor({ serializedEditorState }: Parameters): JSX.Element {
	const initialEditorState = deserializeEditorState(serializedEditorState.replace(/_self/g, `_blank`))
	const [editorState] = React.useState(initialEditorState)

	return (
		<Byte5Editor
			initialContent={editorState.getCurrentContent()}
			updateContent={(): void => {}}
			readOnly
		/>
	)
}

export function ReadOnlyEditorFromText({ text }: { text: string }): JSX.Element {
	return (
		<>
			{text && text !== '' && (
				<Byte5Editor
					initialContent={ContentState.createFromText(text.replace(/_self/g, `_blank`))}
					updateContent={(): void => {}}
					readOnly
				/>
			)}
		</>
	)
}
