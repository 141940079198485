import * as React from "react"
import { FunctionComponent } from "react"
import { FlightTableApp } from "fsg-react-components"
import { IInfoParameters } from "./important-info"
import { IOptionalElementProps, OptionalElement } from "./optional-element"

interface IPressParameters extends IOptionalElementProps {
	content: string
	contentUpdate(content: string): void
	language: string
}

export default class InteractiveModule extends OptionalElement<IPressParameters> {

	protected renderSpecificContent(): JSX.Element {
		const { language } = this.props

		return (
			<div className="col-12 px-0 pb-5">
				<FlightTableApp language={language} />
			</div>
		)
	}
}
