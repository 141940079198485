import * as React from "react"
import phoneIcon from "icons/svg/006_telefon.svg"
import faxIcon from "icons/svg/008_fax.svg"
import emailIcon from "icons/svg/011_email.svg"
import webIcon from "icons/svg/002_web.svg"
import infoIcon from "icons/svg/092_hinweis.svg"
import { IOptionalElementProps, OptionalElement } from "./optional-element"
import { EditableEditor } from "./editable-editor"
import { ReadOnlyEditor } from "./readonly-editor"
import { BoxComponent, emptyBox } from "./information-boxes"

interface IPressParameters extends IOptionalElementProps {
	content: string
	title: string
	contentUpdate(content: string): void
	titleUpdate(content: string): void
	isEnglish: boolean
}

export default class PressContact extends OptionalElement<IPressParameters> {
	protected renderSpecificContent(): JSX.Element {
		const { isAdmin } = this.props

		return <>{isAdmin ? this.editableContent() : this.readOnlyContent()}</>
	}

	private editableContent(): JSX.Element {
		const { content, contentUpdate, titleUpdate, title, isEnglish } = this.props
		let box = null
		try {
			box = JSON.parse(title)
		} catch (error) {
			box = emptyBox()
		}

		return (
			<>
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6">
							<h2 className="headline--lg">
								{isEnglish === true ? "Press Contact" : "Pressekontakt"}
							</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6">
							<p className="paragraph">
								{isEnglish === true
									? "Corporate communication"
									: "Unternehmenskommunikation"}
							</p>
							<p>
								{isEnglish === true
									? "Stuttgart Airport GmbH "
									: "Flughafen Stuttgart GmbH"}{" "}
								<br />
								{isEnglish === true ? "P.O. Box" : "Postfach"} 23 04 61 <br />
								70624 Stuttgart <br />
							</p>
							{box && box.title && (
								<div className="pressBox">
									<BoxComponent
										title={box.title}
										updateBoxTitle={(newBoxTitle): void => {
											box.title = newBoxTitle
											titleUpdate(JSON.stringify(box))
										}}
										email={box.email}
										updateEmail={(newEmail): void => {
											box.email = newEmail
											titleUpdate(JSON.stringify(box))
										}}
										fax={box.fax}
										updateFax={(newFax): void => {
											box.fax = newFax
											titleUpdate(JSON.stringify(box))
										}}
										phone={box.phone}
										updatePhone={(newPhone): void => {
											box.phone = newPhone
											titleUpdate(JSON.stringify(box))
										}}
										web={box.web}
										updateWeb={(newWeb): void => {
											box.web = newWeb
											titleUpdate(JSON.stringify(box))
										}}
										uuid={box.uuid}
										isAdmin
									/>
								</div>
							)}
						</div>
						<div className="col-12 col-md-6">
							<div className="row">
								<div className="col-12 col-md-1">
									<img
										draggable={false}
										className="icon icon--lg icon__info"
										src={infoIcon}
										alt=""
									/>
								</div>
								<div className="col-12 col-md-11">
									<EditableEditor
										serializedEditorState={content}
										textChanged={contentUpdate}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

	private readOnlyContent(): JSX.Element {
		const { title, content, isEnglish } = this.props
		const box = JSON.parse(title)
		return (
			<>
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6">
							<h2 className="headline--lg">
								{isEnglish === true ? "Press Contact" : "Pressekontakt"}
							</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6">
							<p className="paragraph">
								{isEnglish === true
									? "Corporate communication"
									: "Unternehmenskommunikation"}
							</p>
							<p>
								{isEnglish === true
									? "Stuttgart Airport GmbH "
									: "Flughafen Stuttgart GmbH"}{" "}
								<br />
								{isEnglish === true ? "P.O. Box" : "Postfach"} 23 04 61 <br />
								70624 Stuttgart <br />
							</p>
							<div className="pressBox">
								<BoxComponent
									title={box.title}
									email={box.email}
									fax={box.fax}
									phone={box.phone}
									web={box.web}
									uuid={box.uuid}
									isAdmin={false}
								/>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="row">
								<div className="col-12 col-md-1">
									<img
										draggable={false}
										className="icon icon--lg icon__info"
										src={infoIcon}
										alt=""
									/>
								</div>
								<div className="col-12 col-md-11">
									<ReadOnlyEditor serializedEditorState={content} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}
